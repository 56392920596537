body {
  margin: 0;
  font-family: "Averta", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Gotham";
  src: url("./fonts/Gotham-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Gotham book";
  src: url("./fonts/Gotham-Book.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Gotham";
  src: url("./fonts/Gotham-Black.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Gotham";
  src: url("./fonts/Gotham-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Averta";
  src: url("./fonts/Averta-Thin-Italic.woff2") format("woff2"),
    url("./fonts/Averta-Extrathin-Italic.woff2") format("woff2");
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: "Averta";
  src: url("./fonts/Averta.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Averta";
  src: url("./fonts/Averta-Thin.woff2") format("woff2"),
    url("./fonts/Averta-Extrathin.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Averta extra";
  src: url("./fonts/Averta-Extra-Bold-Italic.woff2") format("woff2");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Averta extra";
  src: url("./fonts/Averta-Extra-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Averta";
  src: url("./fonts/Averta-Bold-Italic.woff2") format("woff2");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Averta";
  src: url("./fonts/Averta-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Averta";
  src: url("./fonts/Averta-Black.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Averta";
  src: url("./fonts/Averta-Light-Italic.woff2") format("woff2");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Averta";
  src: url("./fonts/Averta-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Averta";
  src: url("./fonts/Averta-Semibold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Averta";
  src: url("./fonts/Averta-Semibold-Italic.woff2") format("woff2");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Averta";
  src: url("./fonts/Averta-Regular-Italic.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
}
